<template>
  <div class="bg-grey">
    <div class="container-fluid job-listings emp-dashboard px-1 px-md-3">
      <div class="row emp-dashboard-header mt-5 pt-5 pb-5">
        <div class="col-md-2">
          <div class="mt-3" style="cursor: pointer" @click="$router.push({ name: 'Applicant-Jobs' })">
            <img src="@/assets/app-icons/left-arrow.svg" width="10px" />
            <span class="back-route-text"> &nbsp;Back</span>
          </div>
        </div>
        <div class="col-md-9 job-post-card mb-0">
          <div class="job-post-header">
            <div class="left">
              <!-- <div>
                <img :src="job.company_logo_path" class="img-logo" />
              </div> -->
              <div>
                <h5 class="fmp-b mb-0" style="text-transform: capitalize; color: black">
                  {{ jobDetails.job_title }}
                </h5>
              </div>
              <div class="d-flex align-items-center">
                <img src="@/assets/app-icons/clock.svg" width="16px" />
                <span class="pl-1"> {{ jobDetails.days }}d ago </span>
                <!-- <span class="ml-5">Deadline {{ job.deadline }}</span> -->
              </div>
            </div>
          </div>
          <div class="job-post-details">
            <div>
              <h6 class="heading">Salary (Annually)</h6>
              <div class="d-flex">
                <div class="mr-2">{{ jobDetails.currency_code }}</div>
                <div class="pr-2">{{ formattedNumber(jobDetails.job_salary_range_from) }}</div>

                <div class="pr-2">-</div>
                <div class="mr-2">{{ jobDetails.currency_code }}</div>
                <div>{{ formattedNumber(jobDetails.job_salary_range_to) }}</div>
              </div>
            </div>
            <div>
              <h6 class="heading">Job Type</h6>
              <p>{{ jobDetails.job_type }}</p>
            </div>
            <div>
              <h6 class="heading">Location</h6>
              <div class="d-flex job-location">
                <p v-for="(country, country_index) in jobDetails.country" :key="'B' + country_index">
                  {{ country.country_name }}
                </p>
                <p v-for="(city, city_index) in jobDetails.city" :key="'C' + city_index">
                  {{ city.city_name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--End Emp-dashboard-filters-->
      <div class="row mx-1 mt-3 pb-4">
        <b-col class="col-md-12 col-12 white-round mb-5 py-5 text-center">
          <img src="@/assets/app-icons/green-tick.svg" alt="check-icon" />
          <h2 class="fpn-b py-3">Application Successfully Submitted</h2>
        </b-col>
        <div class="col-md-12 lists-table" v-if="jobs.length > 0">
          <h5 class="heading pb-2">Recommended Jobs</h5>
          <!-- <h5
            class="heading pb-2"
            v-if="!applicantLoading && this.applicantDetails.length == 0"
          >
            Applicant Not Found!
          </h5> -->
          <div class="table-responsive text-center mt-4">
            <table class="table">
              <thead class="thead">
                <tr class="d-flex align-items-center">
                  <th class="col-2">Company Name</th>
                  <th class="col-1">Job Title</th>
                  <th class="col-3">Country/City</th>
                  <th v-if="workFlows.basics" class="col-1">Match Rate</th>
                  <th class="col-2">Salary(Annually)</th>
                  <th class="col-1">Date</th>
                  <!-- <th v-if="workFlows.virtual_video_interview" class="col-1">
                    Virtual Video
                  </th>
                  <th class="col-1">Video Profile</th>
                  <th class="col-1">Total Score</th> -->
                  <!-- <th class="col-1">Actions</th> -->
                </tr>
              </thead>
              <tbody>
                <tr class="d-flex" v-for="(data, applicant_details_index) in jobs" :key="'A' + applicant_details_index"
                  @click="$router.push({ path: `/job-details/${data.job_id}` })" style="cursor: pointer">
                  <!-- @click="viewApplicant(data.user_id)" -->
                  <td style="cursor: pointer" class="col-sm-2">
                    {{ data.company_long_name }}
                  </td>
                  <td class="col-sm-1">
                    {{ data.job_title }}
                  </td>
                  <td class="col-sm-3">
                    <span v-for="(c, i) in data.country" :key="i">
                      {{ c.country_name + " " }}
                    </span>
                  </td>
                  <td class="col-sm-1">
                    {{ data.match_percentage.toFixed(2) }}
                  </td>
                  <td class="col-sm-2">
                    <div class="d-flex">
                      <div class="mr-2">{{ data.currency_code }}</div>
                      <div class="pr-2">{{ formattedNumber(data.job_salary_range_from) }}</div>

                      <div class="pr-2">-</div>
                      <div class="mr-2">{{ data.currency_code }}</div>
                      <div>{{ formattedNumber(data.job_salary_range_to) }}</div>
                    </div>
                  </td>
                  <td class="col-sm-1">
                    {{ data.job_start_date }}
                  </td>
                  <!-- <input
                      v-model="data.virtual_video_interview_points"
                      type="number"
                      style="width: 50px"
                    />/20 <button @click="setVideoPoints(data)">Set</button> -->
                  <td></td>
                  <td class="d-flex justify-content-around col-sm-2">
                    <!-- <div class="icon-blue" v-b-tooltip.hover title="Message">
                      <img
                        src="@/assets/app-icons/chat.svg"
                        height="12"
                        width="12"
                        class="chat"
                      />
                    </div>
                    <div class="icon-blue" v-b-tooltip.hover title="Forward">
                      <img
                        src="@/assets/app-icons/forward.svg"
                        height="14"
                        width="14"
                        class="chat"
                      />
                    </div> -->

                    <!-- <div class="icon-blue" v-b-tooltip.hover title="Delete">
                      <img
                        src="@/assets/app-icons/reject.svg"
                        height="12"
                        alt="shortlist"
                        width="12"
                        class="chat"
                      />
                    </div> -->
                    <!-- <div class="icon-blue">
                          <img
                            src="@/assets/app-icons/share.svg"
                            height="12"
                            width="12"
                            class="chat"
                          />
                        </div> -->

                    <!-- <div class="icon-blue" style="cursor: pointer">
                          <img
                            src="@/assets/app-icons/blue-user.svg"
                            @click="viewApplicant(data.user_id)"
                            height="22"
                            width="22"
                            alt="user"
                          />
                        </div> -->

                    <div style="cursor: pointer" v-b-tooltip.hover title="Shortlist">
                      <img v-if="data.shortlisted === 'Y'" src="@/assets/app-icons/o-star.svg" height="22" width="22"
                        @click="addShortlist(data)" />
                      <img v-if="data.shortlisted === 'N'" src="@/assets/app-icons/blue-star.svg" height="22" width="22"
                        alt="shortlist" @click="addShortlist(data)" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="video_modal" size="lg" title="Video">
      <iframe class="modal-img"
        :src="`https://iframe.videodelivery.net/${selectedApplicant && selectedApplicant.length > 0 ? selectedApplicant[0] : null}/thumbnails/thumbnail.jpg?time=1s&height=500`"
        style="border: none" height="500" width="600"
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
    </b-modal>

    <!-- <vue-confirm-dialog></vue-confirm-dialog> -->
  </div>
</template>

<script>
import CloudflareVideoPlayer from "vue-cloudflare-video-player";
import Multiselect from "vue-multiselect";
import RecruiterJobService from "@/apiServices/RecruiterJobService";
import ApplicantJobService from "@/apiServices/ApplicantJobServices";
import moment from "moment";
import numeral from 'numeral'
export default {
  components: { CloudflareVideoPlayer, Multiselect },
  data() {
    return {
      jobDetails: {},
      jobs: [],
      applicantDetails: [],
      selectedApplicant: [],
      countries: [],
      city: [],
      cities: [],
      isLoading: false,
      applicantLoading: true,
      skills: [],
      selectedSub: null,
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 9,
        total: 0,
        searchText: "",
        feature: "Y",
      },
      basicScore: null,
      screenerScore: null,
      qScore: null,
      eqScore: null,
      vScore: null,
      show: false,
      shortlisted: [],
      selectedSkill: [],
      country: [],
      days: null,
      loading: null,
      workFlows: {
        basics: true,
        virtual_interview: true,
        virtual_video_interview: false,
        eq_template: false,
      },
    };
  },
  computed: {
    // shortlistedApp() {
    //   return this.getAllShortlisted();
    // },
  },
  beforeMount() {
    this.getJobDetails();
    //this.getAllAppliedApplicants();
    // this.getAllRecentJobs();
    this.getRecommendedJobs();
  },

  methods: {
    formattedNumber(sal) {
      //console.log(sal);

      return numeral(sal).format('0,0');
    },
    getRecommendedJobs() {
      ApplicantJobService.GetRecommendedJobs().then((res) => {


        this.jobs = res.data.data.map((m) => {
          return {
            ...m,
            job_start_date: moment(m.job_start_date).format("DD-MM-YYYY"),
          };
        });
      });
    },
    getAllRecentJobs() {
      const user_id = this.$cookies.get("user_id");
      // const jobs = await ApplicantJobService.GetAllJobs(this.pagination, {
      //   filters,
      // });
      const job_title = this.$store.getters.getJobTitle ? this.$store.getters.getJobTitle : "";
      console.log('this.$cookies.get("job_title") :>> ', typeof this.$cookies.get("job_title"));
      console.log("job_title_id :>> ", job_title);
      const country_id = this.country ? this.country.country_id : null;
      const pagination = this.pagination;
      ApplicantJobService.GetFeaturedJobs(
        {
          user_id,
          country_id,
          job_title,
        },
        pagination
      )
        .then((res) => {

          this.jobs = res.data.data.data;
          this.jobs = this.jobs.map((m) => {
            return {
              ...m,
              job_start_date: moment(m.job_start_date).format("DD-MM-YYYY"),
            };
          });
        })
        .catch((err) => {

        });
    },
    getJobDetails() {
      let job_id = this.$route.params.job_id;
      RecruiterJobService.GetSingleJob({ job_id }).then((res) => {

        this.jobDetails = res.data.data[0];
        const now = moment();
        const created_at = moment(this.jobDetails.job_start_date).format("YYYY-MM-DD");
        const day = now.diff(created_at, "days");
        console.log("day :>> ", day);
        this.jobDetails.days = day;
      });
    },
    getAllAppliedApplicants() {
      RecruiterJobService.GetAllAppliedApplicants().then((res) => {

        this.applicantDetails = res.data.data;
      });
    },
    addShortlist(user) {
      const payload = {
        user_id: user.user_id,
        job_id: user.job_id,
        company_id: this.$cookies.get("company_id"),
        shortlisted: "Y",
      };
      RecruiterJobService.AddShortlist(payload)
        .then((s) => {
          console.log("s :>> ", s);
          this.getAllAppliedApplicants();

          this.$toasted.show("Applicant Shortlisted!", {
            duration: 6000,
            icon: "check",
          });
        })
        .catch((e) => {
          this.$toasted.show("Applicant already shortlisted!", {
            duration: 6000,
            icon: "clear",
          });
        });
    },
    handleClick(application) {
      this.$confirm({
        message: `Are you sure you want to reject this application?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            // ... do something
            console.log("confirm :>> ", confirm);
            this.rejectApplication(application);
          }
        },
      });
    },
    async rejectApplication(application) {
      try {
        console.log("application:: :>> ", application);
        let response = await RecruiterJobService.RejectApplication({
          job_apply_id: application.job_apply_id,
        });
        let { data } = response;
        if (data && data.status) {
          this.$toasted.show(data.message, {
            duration: 6000,
            icon: "check",
          });
          this.getAllAppliedApplicants();
        } else {
          this.$toasted.show(data.message, {
            duration: 6000,
            icon: "check",
          });
        }
      } catch (error) {

      }
    },
  },
};
</script>

<style lang="css" scoped>
.emp-dashboard .icon-blue {
  cursor: pointer;
}

.multis>>>.multiselect__tags {
  /* color: orange; */
  background-color: #eaeaea;
}

.multis>>>.multiselect__tag {
  background: #6244e5;
}

.multis>>>.multiselect__tag-icon:after {
  color: white;
}

.multis>>>.multiselect__tag-icon:hover {
  background: #6244e5;
}

.multis>>>.multiselect__option--highlight {
  background: #6244e5;
}

.multis>>>.multiselect__option:after {
  background-color: #6244e5;
  color: white;
}

.multis>>>.multiselect__input {
  background-color: #eaeaea;
}

.multiselect-dropdown span {
  margin-right: 10px;
}
</style>
